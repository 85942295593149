<template>
  <div class="vegetables st-wrapper" ref="vegetables">
    <div class="sub-title">菜类/水果进场登记</div>
    <!-- 基础信息 -->
    <div class="card base-info">
      <div class="title card-field">
        <van-badge color="none">
          <span> 基础信息</span>
        </van-badge>
      </div>
      <div
        class="card-field"
        required
        data-label="source_goods_name"
        @click="onSelectKind()"
      >
        <div class="name"><i>*</i> <span>产品名称</span></div>
        <div class="right">
          <input
            type="text"
            v-model="sendData.source_goods_name"
            placeholder="请输入"
            readonly
          />
          <img src="../../../assets/images/icon/arrow-r-m-03.png" alt="" />
        </div>
      </div>
      <div class="card-field" required data-label="kind">
        <div class="name"><i>*</i> <span>产品种类</span></div>
        <input type="text" v-model="sendData.kind" placeholder="请输入" />
      </div>
      <div
        class="card-field"
        required
        data-label="shipment"
        data-type="integer"
      >
        <div class="name"><i>*</i> <span>进场重量(kg)</span></div>
        <input
          type="number"
          v-model.number="sendData.shipment"
          @input="checkout('shipment')"
          placeholder="请输入"
        />
      </div>
      <div
        class="card-field"
        @click="testing_result_show = true"
        required
        data-label="testing_result"
      >
        <div class="name"><i>*</i> <span>检测结果</span></div>
        <input
          v-model="sendData.testing_result"
          placeholder="请选择"
          readonly
        />
      </div>
    </div>
    <!-- 产地信息 -->
    <div class="card origin-info">
      <div class="title card-field">
        <van-badge color="#fff">
          <span> 产地信息</span>
        </van-badge>
      </div>
      <div class="card-field">
        <div class="name"><i></i> <span>产地</span></div>
        <input v-model="sendData.place_of_origin" placeholder="请输入" />
      </div>
      <div class="card-field">
        <div class="name"><i></i> <span>产地检疫证号</span></div>
        <input
          v-model="sendData.inspection_and_quarantine"
          placeholder="请输入"
        />
      </div>
      <div class="card-field upload-field">
        <van-uploader
          class="upload"
          v-model="fileList"
          :after-read="uploader"
          @delete="hanldeDelImg"
        >
          <div class="upload-wrap">
            <img src="../../../assets/images/icon/entry-upload.png" alt="" />
          </div>
        </van-uploader>
        <span v-show="fileList.length === 0">请上传产地检疫证图片</span>
      </div>
    </div>
    <footer-button text="提交" @confirm="confirm" />
    <!--  -->
    <van-action-sheet
      v-model="testing_result_show"
      :actions="testing_result_actions"
      cancel-text="取消"
      description="请选择检测结果"
      close-on-click-action
      @select="selectTestingResult"
    />
  </div>
</template>

<script>
import { Uploader, Badge, Icon, ActionSheet, Dialog } from 'vant'
import FooterButton from '../../../components/footer/button/Index'
import { randomString } from '../../../utils/index'
export default {
  name: 'EntryVegetables',
  components: {
    [Uploader.name]: Uploader,
    [Badge.name]: Badge,
    [Icon.name]: Icon,
    [ActionSheet.name]: ActionSheet,
    FooterButton
  },
  data () {
    return {
      isAdd: true,
      index: null,
      fileList: [],
      testing_result_show: false, // 检测结果 是否展示
      testing_result_actions: [{ name: '合格' }, { name: '不合格' }], // 检测结果 选择项
      sourceData: {},
      // 发送 数据
      sendData: {
        sn: '', // 商品编号
        type: 'veg',
        source_goods_name: '', // 商品名称
        create_time: '', // 创建时间
        enterprise_name: '', // 屠宰企业名称
        goods_id: null, // 商品id
        inspection_and_quarantine: '', // 产地检疫号
        inspection_and_quarantine_list: [], // 产地检疫号图片(前后端传输字段)
        inspection_and_quarantine_url: '', // 产地检疫号图片
        kind: '', // 产品种类
        testing_result: '', // 是否 合格
        meat_quarantine_number: '', // 肉类检疫证号
        meat_quarantine_number_list: [], // 肉类检疫证号图片(前后端传输字段)
        meat_quarantine_number_url: '', // 肉类检疫证号图片
        number_of_branches: '', // 进场头数
        place_of_origin: '', // 产地
        shipment: '', // 发货数量
        the_product_number: '', // 产品证号
        the_product_number_list: [], // 产品证号图片(前后端传输字段)
        the_product_number_url: '' // 产品证号图片
      }
    }
  },
  created () {
    const queryData = this.$route.query
    // console.log('接受到的参数：', queryData)
    if (queryData.index === null) {
      this.isAdd = true
    } else {
      this.index = queryData.index
      const sourceInfo = this.$store.state.sourceInfo
      const sourceListIndex = sourceInfo.source_list[queryData.index]
      this.isAdd = false
      this.sendData = JSON.parse(JSON.stringify(sourceListIndex))
      this.fileList = sourceListIndex.inspection_and_quarantine_list.map(item => {
        return {
          url: item,
          isImage: true
        }
      })
    }

    if (queryData.sourceGoodsName) {
      const sourceList = this.$store.state.sourceGoods
      this.sendData = JSON.parse(JSON.stringify(sourceList))
      this.fileList = sourceList.inspection_and_quarantine_list.map(item => {
        return {
          url: item,
          isImage: true
        }
      })
    }
  },
  methods: {
    // 正则检测
    checkout (val) {
      let field = this.sendData[val]
      // eslint-disable-next-line no-useless-escape
      field = field.toString().replace(/[^\d\.]|^\./g, '').replace(/\.{2}/g, '.').replace(/^([1-9]\d*|0)(\.\d{1,2})(\.|\d{1})?$/, '$1$2').replace(/^0\d{1}/g, '0')
      this.sendData[val] = parseFloat(field)
    },
    // 选择产品名称
    onSelectKind () {
      // console.log(this.$store.state.sourceInfo.shop_id)
      this.$store.dispatch('setSourceGoods', this.sendData)
      this.$router.push({
        name: 'SelectKind',
        query: {
          shopId: this.$store.state.sourceInfo.shop_id || this.$route.query.shopId,
          index: this.index,
          type: 'veg'
        }
      })
    },
    // select 选择 检测结果
    selectTestingResult (item) {
      this.sendData.testing_result = item.name
      this.show = false
    },
    // 上传 检疫证图片
    uploader (file) {
      const formData = new FormData()
      formData.append('file', file.file, file.file.name)
      this.$api.entry.uploaders(formData).then(res => {
        // console.log(res)
        if (res.status === 200) {
          this.sendData.inspection_and_quarantine_list.push(res.data.url)
        }
      })
    },
    // 图片 删除
    hanldeDelImg (file) {
      this.sendData.inspection_and_quarantine_list = this.fileList.map(item => {
        return item.url
      })
    },
    // 提交
    confirm () {
      const fields = this.$refs.vegetables.querySelectorAll('.card-field')
      // console.log(fields)
      for (let i = 0; i < fields.length; i++) {
        const item = fields[i]
        if (item.getAttribute('required')) {
          if (this.sendData[item.getAttribute('data-label')] === '') {
            // console.log(this.sendData[item.getAttribute('data-label')])
            Dialog({ message: '请填写' + item.querySelector('span').innerHTML })
            return false
          }
          if (item.getAttribute('data-type') === 'number' && (typeof this.sendData[item.getAttribute('data-label')]) !== 'number') {
            // console.log(typeof this.sendData[item.getAttribute('data-label')])
            Dialog({ message: item.querySelector('span').innerHTML + '输入的值格式不对' })
            return false
          }
        }
      }

      // if (this.sendData.inspection_and_quarantine_list.length === 0) {
      //   Dialog({ message: '请上传产地检疫证' })
      //   return false
      // }

      this.sendData.sn = randomString() // sn 随机 字符串
      this.sourceData = this.sendData
      const sourceInfo = this.$store.state.sourceInfo
      if (this.isAdd) {
        sourceInfo.source_list.push(this.sourceData)
      } else {
        sourceInfo.source_list[this.index] = this.sourceData
      }
      this.$store.dispatch('setSourceInfo', sourceInfo)
      this.$router.push({
        name: 'EntryRegister',
        query: {
          isNewGoods: true,
          cartId: sourceInfo.cart_id ? sourceInfo.cart_id : null,
          shopId: sourceInfo.shop_id,
          shopName: sourceInfo.shop_name,
          marketMerchantId: sourceInfo.farmers_market_merchant_id,
          marketId: sourceInfo.farmers_markets_id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
